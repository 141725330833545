.card {
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
  }
  
  .card2 {
    background-color: aqua;
  }
  